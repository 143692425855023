
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, reactive, onMounted, ref, PropType } from 'vue';

import router from '@/router';

import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import FloorTrakOrbisCard from '@/components/FloorTrakOrbisCard.vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import QuantityPicker from '@/components/QuantityPicker.vue';

import TagListener from '@/modules/floortrak/services/TagListener';
import SafetyInspectionQuestionnaire from '@/modules/floortrak/view/shared/components/SafetyInspectionQuestionnaire.vue';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import useLoading from '@/modules/floortrak/composables/useLoading';
import { ReceivingAction } from '@/modules/floortrak/domain/enums/ReceivingAction';

import { daysBack } from '@/functions/date';
import { useNotification } from '@/composable/useNotifications';

import Item from '@/domain/Item';
import { ItemType } from '@/domain/enums/ItemType';
import Location from '@/domain/Location';
import Transaction from '@/domain/Transaction';
import TransactionLine from '@/domain/TransactionLine';
import Shipment from '@/domain/Shipment';
import Dock from '@/domain/Dock';
import Carrier from '@/domain/Carrier';
import TrailerType from '@/domain/TrailerType';
import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';

import CarrierService from '@/modules/master-data/services/CarrierService';
import DockService from '@/services/DockService';
import LabelGenerationService from '@/services/LabelGenerationService';
import ReceivingService from '@/services/ReceivingService';
import TrailerTypeService from '@/services/TrailerTypeService';
import SafetyInspectionService from '@/services/SafetyInspectionService';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

import useValidator from '@/validation/useValidator';
import SafetyInspection from '@/domain/SafetyInspection';
import ApplicationUser from '@/domain/ApplicationUser';
import CoreStore from '@/store/CoreStore';

import TrackedItemTagModal from '@/components/TrackedItemTagModal.vue';
import TrackedItem from '@/domain/TrackedItem';
import FloortrakReceivingTagScanHandler from '@/services/tag-scanning/scan-handler/FloortrakReceivingTagScanHandler';
import AllOrOnRouteItemSelector from '@/components/AllOrOnRouteItemSelector.vue';

enum NextView {
    EXIT = 'exit',
    SORT = 'sort',
    PUT_AWAY = 'put-away',
    UNLOAD = 'unload-complete',
}

type State = {
    loading: boolean;
    search: number | undefined;
    shipment: Shipment;
    showSearch: boolean;
    searching: boolean;
    showPrintLabelsModal: boolean;
    labelQuantity: number | undefined;
    printingLabels: boolean;
    showSafetyInspection: boolean;
    showSafetyChecklist: boolean;
    showEquipmentInspection: boolean;
    carriers: Array<Carrier>;
    docks: Array<Dock>;
    trailerTypes: Array<TrailerType>;
    showItemSearch: boolean;
    unloadComplete: boolean;
    showPostUnloadSafetyInspection: boolean;
    postUnloadSafetyInspection: SafetyInspection;
    tags: Array<TrackedItem>;
};

interface ItemTableData {
    item: Item;
    name: string;
    estimatedQuantity: number;
    imageUrlThumb: string | undefined;
    imageUrlFull: string | undefined;
    tags: Array<TrackedItem>;
}

export default defineComponent({
    name: 'existing',
    components: {
        Thumbnail,
        BSpinner,
        BFormInput,
        BTable,
        FloorTrakOrbisCard,
        SmartTrakFooter,
        DropdownAutocompleteSingleSelect,
        QuantityPicker,
        SafetyInspectionQuestionnaire,
        TrackedItemTagModal,
        AllOrOnRouteItemSelector
    },
    props: {
        transactionNumber: {
            type: String,
            required: false,
            default: undefined,
        }, // this is a route param, it has to be a string => convert to number onSearch
    },
    setup(props) {
        const receivingService = new ReceivingService();
        const labelGenerationService = new LabelGenerationService();
        const carrierService = new CarrierService();
        const dockService = new DockService();
        const trailerService = new TrailerTypeService();
        const safetyInspectionService = new SafetyInspectionService();

        const originalShipmentStringified = ref<string>('');

        const arrivalDateLowerLimit = new Date(daysBack(14).setHours(0, 0, 0));
        const arrivalDateUpperLimit = new Date(new Date().setHours(23, 59, 59));

        const { setLoading } = useLoading();
        const notification = useNotification();
        const { confirm } = useDialogBox();

        const state = reactive<State>({
            loading: false,
            search: undefined,
            shipment: new Shipment(),
            showSearch: true,
            searching: false,
            showPrintLabelsModal: false,
            labelQuantity: undefined,
            printingLabels: false,
            showSafetyInspection: false,
            showSafetyChecklist: false,
            showEquipmentInspection: true,
            carriers: [],
            docks: [],
            trailerTypes: [],
            showItemSearch: false,
            unloadComplete: false,
            showPostUnloadSafetyInspection: false,
            postUnloadSafetyInspection: new SafetyInspection(),
            tags: [],
        });

        const { validateForm, validationResult } = useValidator<Shipment>('existing-receiving-shipment');

        const isDirty = computed((): boolean => JSON.stringify(state.shipment) !== originalShipmentStringified.value);

        const canSubmit = computed((): boolean => transaction.value.transactionLines?.length > 0);

        const tagScanListener = new TagListener(searchItem);
        const tags = computed((): Array<TrackedItem> => state.tags as Array<TrackedItem>);

        const transaction = computed(
            (): Transaction => {
                if (state.shipment.transactions.length > 0) {
                    return state.shipment.transactions[0] as Transaction;
                }
                return new Transaction();
            },
        );

        const tableData = computed(
            (): Array<ItemTableData> => {
                if (transaction.value && transaction.value.transactionLines && !state.loading) {
                    return mapTransLinesToTableData(transaction.value.transactionLines as Array<TransactionLine>);
                }
                return [];
            },
        );

        const fields = computed(
            (): Array<BTableField<ItemTableData & { action: string }>> => {
                const baseFields: Array<BTableField<ItemTableData & { action: string }>> = [
                    {
                        key: 'imageUrlThumb',
                        label: getTitleCaseTranslation('core.domain.image'),
                        width: '70px',
                        ignoreSort: true,
                    },
                    {
                        key: 'name',
                        label: getTitleCaseTranslation('core.domain.name'),
                    },
                    {
                        key: 'estimatedQuantity',
                        width: '450px',
                        label: getTitleCaseTranslation('core.common.estimatedQuantityShort'),
                    },
                ];
                return baseFields;
            },
        );

        onBeforeMount(async () => {
            tagScanListener.startListener();
            state.shipment.dock = new Dock({
                id: 0,
                name: '',
            });
            state.shipment.addTransaction(new Transaction());
            state.shipment.transactions[0].toLocation = new Location({
                id: 0,
                name: '',
            });
            if (props.transactionNumber) {
                state.showSearch = false;
                state.search = parseInt(props.transactionNumber, 10);
                await searchExisting();
                if (!state.shipment?.id) {
                    state.showSearch = true;
                }
            }
        });

        onBeforeUnmount(() => {
            tagScanListener.stopListener();
        });

        onMounted(async () => {
            state.loading = true;

            const carriers = await carrierService.getAllCarriers();
            if (carriers.success) {
                state.carriers = carriers.carriers;
            }

            const trailerTypes = await trailerService.getAllTrailerTypes();
            if (trailerTypes.success) {
                state.trailerTypes = trailerTypes.trailerTypes;
            }

            state.loading = false;
        });

        function closeSearch() {
            state.search = undefined;
            state.showSearch = false;
        }

        function escape() {
            closeSearch();
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        function addTag(trackedItem: TrackedItem): void {
            transaction.value.addEstimatedItemQty(trackedItem.item, 1);
            transaction.value.addTrackedItem(trackedItem);
            state.tags.push(trackedItem);
        }

        const tagHandler = new FloortrakReceivingTagScanHandler(transaction, tags, addTag);

        async function searchItem(barcodes: Array<string>) {
            await tagHandler.performSearch(barcodes);
        }

        function getCurrentTagsForItem(item: Item): Array<TrackedItem> {
            return state.tags.filter((tag) => tag.item.id === item.id);
        }

        function removeTag(tag: TrackedItem) {
            transaction.value.removeTrackedItem(tag);
            transaction.value.addEstimatedItemQty(tag.item, -1);
            const stateTagIndex = state.tags.findIndex((x) => x.id === tag.id);
            state.tags.splice(stateTagIndex, 1);
        }

        async function searchExisting() {
            state.loading = true;
            if (state.search) {
                state.searching = true;
                const shipment = await receivingService.findReceivingShipment(state.search, ReceivingAction.EXISTING);
                if (shipment) {
                    closeSearch();
                    setStateShipment(shipment);
                    await getDocks(transaction.value.toLocation);

                    const inspectionExists = await safetyInspectionService.trailerInspectionOfTypePassesForShipment(shipment.id!, SafetyInspectionType.PreUnload);

                    state.showSafetyChecklist = inspectionExists;
                    state.showSafetyInspection = !inspectionExists;

                    state.unloadComplete = await safetyInspectionService.trailerInspectionOfTypePassesForShipment(shipment.id!, SafetyInspectionType.PostUnload);;
                }
                state.searching = false;
            }
            state.loading = false;
        }

        async function getDocks(selection: Location) {
            state.docks = await dockService.getInServiceReceivingDocksByLocationId(selection.id);
        }

        async function confirmTransaction() {
            const confirmResponse = await confirm({
                title: getTitleCaseTranslation('core.common.confirmTransaction'),
                message: getTranslation('core.common.confirmInboundReceiptFrom', transaction.value.id, transaction.value.fromLocation.name),
            });
            if (confirmResponse) {
                state.loading = true;
                const response = await receivingService.confirmExistingTransaction(transaction.value.id);
                if (response.success) {
                    setStateShipment(response.shipment);
                    originalShipmentStringified.value = JSON.stringify(state.shipment);
                } else {
                    notification.showError(response.message);
                    state.showSearch = true;
                }
                state.loading = false;
            } else {
                router.push({ name: FloorTrakRouteTypes.HOME });
            }
        }

        function mapTransLinesToTableData(lines: Array<TransactionLine>): Array<ItemTableData> {
            return lines.map((line) => ({
                item: line.item,
                name: line.item.name,
                estimatedQuantity: line.estimatedQuantity,
                tags: getCurrentTagsForItem(line.item),
                imageUrlFull: line.item.imageUrlFull,
                imageUrlThumb: line.item.imageUrlThumb,
            }));
        }

        async function saveShipment(title: string, nextView: NextView) {
            if (canSubmit.value) {
                let saveResponse = await confirm({
                    title: title,
                    message: getTranslation('core.domain.saveShipment'),
                });
                if (saveResponse) {
                    validateForm(state.shipment as Shipment);
                    if (validationResult.isValid) {
                        setLoading(true);
                        // transactionLine increments actualQuantity for tag scans, this clears that value
                        // having an actualQuantity before put away and sort causes undesired behavior
                        transaction.value.clearActualQuantityForReceiving();
                        const response = await receivingService.updateConfirmedShipment(<Shipment>state.shipment, transaction.value.id);
                        if (response.success) {
                            if (nextView === NextView.EXIT) {
                                await router.push({ name: FloorTrakRouteTypes.HOME });
                            } else if (nextView === NextView.UNLOAD) {
                                //stay on this screen
                            } else {
                                await router.push({
                                    name: nextView === NextView.SORT ? FloorTrakRouteTypes.RECEIVING.SORT : FloorTrakRouteTypes.RECEIVING.PUT_AWAY,
                                    params: { transactionNumber: transaction.value.id },
                                });
                            }
                        } else {
                            notification.showError(response.message);
                        }
                        setLoading(false);
                    }
                }
            }
        }

        function openPrintLabelsModal(): void {
            state.showPrintLabelsModal = true;
        }

        async function saveBefore() {
            if (!isDirty.value) {
                return true;
            }

            let response = await confirm({
                title: getTitleCaseTranslation('core.button.saveAndPrint'),
                message: getTranslation('core.validation.changesSavedBeforePrintingAreYouSure'),
            });
            if (response) {
                state.loading = true;
                const saveResponse = await receivingService.updateShipment(<Shipment>state.shipment);
                if (saveResponse.success) {
                    setStateShipment(saveResponse.shipment);
                }
                response = saveResponse.success;
                state.loading = false;
            }
            return response;
        }

        async function printLabels() {
            state.showPrintLabelsModal = false;

            if (!state.labelQuantity) {
                notification.showError(getTranslation('core.validation.quantityMustBeSpecified'));
                return;
            }

            if (!(await saveBefore())) {
                return;
            }

            state.printingLabels = true;
            const labelUrl = await labelGenerationService.getInboundLabelsUrl(transaction.value.id, state.labelQuantity!);
            state.printingLabels = false;
            state.labelQuantity = undefined;
            if (labelUrl) {
                window.location.href = labelUrl;
            }
        }

        async function printInboundReceipt() {
            validateForm(state.shipment as Shipment);

            if (validationResult.isValid) {
                if (!(await saveBefore())) {
                    return;
                }

                state.loading = true;
                setLoading(true);
                await receivingService.getTransactionReceipt(transaction.value.id);
                setLoading(false);
                state.loading = false;
            }
        }

        function selectCarrier(carrier: Carrier | null) {
            if (carrier) {
                state.shipment.carrierId = carrier.id;
                state.shipment.carrier = carrier;
            } else {
                state.shipment.carrierId = undefined;
                state.shipment.carrier = undefined;
            }
        }

        function selectDock(dock: Dock | null) {
            if (dock) {
                state.shipment.dock = dock;
            } else {
                state.shipment.dock = undefined;
            }
        }

        function selectTrailerType(trailerType: TrailerType | null) {
            if (trailerType) {
                state.shipment.trailerType = trailerType;
            } else {
                state.shipment.trailerType = new TrailerType();
            }
        }

        function setArrivalDate(arrivalDate: Date | null) {
            state.shipment.arrivalDate = arrivalDate;
            transaction.value.arrivedAt = arrivalDate as any;
        }

        function setStateShipment(shipment: Shipment) {
            shipment.dock = undefined;
            state.shipment = shipment;
            state.shipment.fromLocation = transaction.value.fromLocation;
        }

        function changeLineQuantity(item: Item, quantity: number) {
            var tags = getCurrentTagsForItem(item);
            if (quantity < tags.length && tags.length > 0) {
                notification.showError(`${getTranslation('core.validation.qtyCannotBeLessThanTotalTagsForItem', quantity)} (${tags.length})`);
            }
            else {
                transaction.value.setEstimatedItemQty(item, quantity);
            }
        }

        function openItemSearch() {
            state.showItemSearch = true;
        }

        function addItem(data: { item: Item; quantity: number }) {
            if (transaction.value) {
                transaction.value.addEstimatedItemQty(data.item, data.quantity);
            }
            state.showItemSearch = false;
        }

        function cancelSafetyInspection() {
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        function showPostUnloadSafetyInspection() {
            validateForm(state.shipment as Shipment);
            if (validationResult.isValid) {
                state.showPostUnloadSafetyInspection = true
            }
        }

        async function unloadComplete(inspection: SafetyInspection) {
            state.postUnloadSafetyInspection = inspection;
            state.unloadComplete = true;
            await safetyInspectionService.associateShipmentWithTrailerInspection(state.shipment.id!, state.postUnloadSafetyInspection.id);
        }

        async function finalizeEstimate() {
            const confirmFinalize = await confirm({
                title: getTitleCaseTranslation('core.domain.finalizeEstimate'),
                message: getTranslation('core.domain.confirmFinalizeMessage'),
            });
            
            if (confirmFinalize) {
                state.loading = true;
                // Set the transaction to Delivery Confirmed
                const confirm = await receivingService.updateConfirmedShipment(<Shipment>state.shipment, transaction.value.id);
                if (confirm) {
                    // findReceivingShipmet hydrates the DTOs
                    let shipment = await receivingService.findReceivingShipment(transaction.value.id, ReceivingAction.ESTIMATE);
                    if (shipment) {
                        const response = await receivingService.finalizeEstimate(shipment.transactions[0]);
                        if (response.success) {
                            state.shipment.transactions[0].isEstimateFinalized = true;
                        }
                    }
                }
                state.loading = false;
            }
        }

        return {
            state,
            transaction,
            fields,
            ItemType,
            tableData,
            searchExisting,
            canSubmit,
            escape,
            openPrintLabelsModal,
            printLabels,
            printInboundReceipt,
            confirmTransaction,
            selectCarrier,
            selectDock,
            selectTrailerType,
            saveShipment,
            arrivalDateLowerLimit,
            arrivalDateUpperLimit,
            setArrivalDate,
            validationResult,
            getTitleCaseTranslation,
            getTranslation,
            NextView,
            changeLineQuantity,
            openItemSearch,
            addItem,
            SafetyInspectionType,
            cancelSafetyInspection,
            unloadComplete,
            showPostUnloadSafetyInspection,
            finalizeEstimate,
            removeTag
        };
    },
});
