import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73cef90a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "existing-table-container" }
const _hoisted_2 = { class: "row-container" }
const _hoisted_3 = { class: "quantity-picker-container" }
const _hoisted_4 = { class: "tags-container" }
const _hoisted_5 = { class: "existing-table-empty" }
const _hoisted_6 = { class: "existing-table-footer" }
const _hoisted_7 = { style: {"padding-top":"20px","display":"flex","justify-content":"flex-end"} }
const _hoisted_8 = { style: {"margin-right":"5px"} }
const _hoisted_9 = { style: {"margin-right":"5px"} }
const _hoisted_10 = { style: {"margin-right":"5px"} }

export function render(_ctx: any,_cache: any) {
  const _component_b_banner = _resolveComponent("b-banner")!
  const _component_safety_inspection_questionnaire = _resolveComponent("safety-inspection-questionnaire")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_dropdown_autocomplete_single_select = _resolveComponent("dropdown-autocomplete-single-select")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_quantity_picker = _resolveComponent("quantity-picker")!
  const _component_TrackedItemTagModal = _resolveComponent("TrackedItemTagModal")!
  const _component_b_table = _resolveComponent("b-table")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_floor_trak_orbis_card = _resolveComponent("floor-trak-orbis-card")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_all_or_on_route_item_selector = _resolveComponent("all-or-on-route-item-selector")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_smart_trak_footer = _resolveComponent("smart-trak-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_floor_trak_orbis_card, {
      title: _ctx.getTitleCaseTranslation('core.common.existing')
    }, {
      default: _withCtx(() => [
        (_ctx.state.shipment.transactions[0].isEstimateFinalized)
          ? (_openBlock(), _createBlock(_component_b_banner, { key: 0 }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.getTranslation('core.validation.receiptAlreadyEstimated')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx.state.showEquipmentInspection)
          ? (_openBlock(), _createBlock(_component_safety_inspection_questionnaire, {
              key: 1,
              modelValue: _ctx.state.showSafetyInspection,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showSafetyInspection) = $event)),
              "inspection-type": _ctx.SafetyInspectionType.PreUnload,
              carriers: _ctx.state.carriers,
              shipment: (_ctx.state.shipment as Shipment),
              "inspection-title": _ctx.getTitleCaseTranslation('core.common.safetyChecklist'),
              onCanceled: _ctx.cancelSafetyInspection,
              onRejected: _ctx.cancelSafetyInspection,
              onPassed: _ctx.confirmTransaction
            }, null, 8, ["modelValue", "inspection-type", "carriers", "shipment", "inspection-title", "onCanceled", "onRejected", "onPassed"]))
          : _createCommentVNode("", true),
        (!_ctx.state.showEquipmentInspection)
          ? (_openBlock(), _createBlock(_component_safety_inspection_questionnaire, {
              key: 2,
              modelValue: _ctx.state.showSafetyChecklist,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.showSafetyChecklist) = $event)),
              "inspection-type": _ctx.SafetyInspectionType.SafetyOnly,
              carriers: _ctx.state.carriers,
              shipment: (_ctx.state.shipment as Shipment),
              "inspection-title": _ctx.getTitleCaseTranslation('core.common.safetyChecklist'),
              onCanceled: _ctx.cancelSafetyInspection,
              onRejected: _ctx.cancelSafetyInspection,
              onPassed: _ctx.confirmTransaction
            }, null, 8, ["modelValue", "inspection-type", "carriers", "shipment", "inspection-title", "onCanceled", "onRejected", "onPassed"]))
          : _createCommentVNode("", true),
        (!_ctx.state.loading)
          ? (_openBlock(), _createBlock(_component_safety_inspection_questionnaire, {
              key: 3,
              modelValue: _ctx.state.showEquipmentInspection,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.showEquipmentInspection) = $event)),
              "inspection-type": _ctx.SafetyInspectionType.EquipmentInspection,
              "inspection-title": _ctx.getTitleCaseTranslation('core.common.equipmentSafetyInspection'),
              onCanceled: _ctx.cancelSafetyInspection,
              onRejected: _ctx.cancelSafetyInspection
            }, null, 8, ["modelValue", "inspection-type", "inspection-title", "onCanceled", "onRejected"]))
          : _createCommentVNode("", true),
        _createVNode(_component_safety_inspection_questionnaire, {
          modelValue: _ctx.state.showPostUnloadSafetyInspection,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.showPostUnloadSafetyInspection) = $event)),
          carriers: _ctx.state.carriers,
          "inspection-type": _ctx.SafetyInspectionType.PostUnload,
          shipment: (_ctx.state.shipment as Shipment),
          "inspection-title": _ctx.getTitleCaseTranslation('core.common.safetyChecklist'),
          onPassed: _ctx.unloadComplete
        }, null, 8, ["modelValue", "carriers", "inspection-type", "shipment", "inspection-title", "onPassed"]),
        _createVNode(_component_b_form, { class: "container-fluid" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_row, { class: "form-col" }, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  modelValue: _ctx.state.shipment.id,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.shipment.id) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.domain.shipmentWithNumberSymbol'),
                  cols: "2",
                  loading: _ctx.state.loading,
                  disabled: ""
                }, null, 8, ["modelValue", "label", "loading"]),
                _createVNode(_component_text_input, {
                  modelValue: _ctx.transaction.id,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.transaction.id) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.domain.transactionWithNumberSymbol'),
                  cols: "2",
                  loading: _ctx.state.loading,
                  disabled: ""
                }, null, 8, ["modelValue", "label", "loading"]),
                _createVNode(_component_text_input, {
                  modelValue: _ctx.transaction.fromLocation.name,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.transaction.fromLocation.name) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.common.from'),
                  cols: "4",
                  loading: _ctx.state.loading,
                  disabled: ""
                }, null, 8, ["modelValue", "label", "loading"]),
                _createVNode(_component_text_input, {
                  modelValue: _ctx.transaction.toLocation.name,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.transaction.toLocation.name) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.common.to'),
                  cols: "4",
                  loading: _ctx.state.loading,
                  disabled: ""
                }, null, 8, ["modelValue", "label", "loading"])
              ]),
              _: 1
            }),
            _createVNode(_component_b_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_dropdown_autocomplete_single_select, {
                  loading: _ctx.state.loading,
                  cols: "6",
                  "model-value": _ctx.state.shipment.carrierName,
                  data: _ctx.state.carriers,
                  "search-by": "name",
                  "empty-text": _ctx.getTranslation('core.common.noCarrierSelected'),
                  "display-name": "name",
                  label: _ctx.getTitleCaseTranslation('core.domain.carrier'),
                  class: "form-col",
                  onOnSelect: _ctx.selectCarrier
                }, null, 8, ["loading", "model-value", "data", "empty-text", "label", "onOnSelect"]),
                _createVNode(_component_text_input, {
                  modelValue: _ctx.state.shipment.carrierReferenceNumber,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.shipment.carrierReferenceNumber) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.domain.shippingReferenceNumber'),
                  cols: "6",
                  class: "form-col"
                }, null, 8, ["modelValue", "label"])
              ]),
              _: 1
            }),
            _createVNode(_component_b_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_date_input, {
                  "model-value": _ctx.state.shipment.arrivalDate,
                  "input-format": "MM-dd-yyyy, h:mm:ss a",
                  "starting-view": "day",
                  "minimum-view": "time",
                  label: _ctx.getTitleCaseTranslation('core.domain.dockArrivalTime'),
                  cols: "3",
                  class: "form-col",
                  "lower-limit": _ctx.arrivalDateLowerLimit,
                  "upper-limit": _ctx.arrivalDateUpperLimit,
                  "onUpdate:modelValue": _ctx.setArrivalDate
                }, null, 8, ["model-value", "label", "lower-limit", "upper-limit", "onUpdate:modelValue"]),
                _createVNode(_component_dropdown_autocomplete_single_select, {
                  loading: _ctx.state.loading,
                  cols: "3",
                  "model-value": _ctx.state.shipment.dockName,
                  data: _ctx.state.docks,
                  "search-by": "name",
                  "empty-text": _ctx.getTranslation('core.common.noDockSelected'),
                  "display-name": "name",
                  label: _ctx.getTitleCaseTranslation('core.domain.dockDoor'),
                  class: "form-col",
                  error: _ctx.validationResult?.model.dock,
                  onOnSelect: _ctx.selectDock
                }, null, 8, ["loading", "model-value", "data", "empty-text", "label", "error", "onOnSelect"]),
                _createVNode(_component_dropdown_autocomplete_single_select, {
                  loading: _ctx.state.loading,
                  cols: "3",
                  "model-value": _ctx.state.shipment.trailerTypeName,
                  data: _ctx.state.trailerTypes,
                  "search-by": "name",
                  "empty-text": _ctx.getTranslation('core.common.noTrailerTypes'),
                  "display-name": "name",
                  label: _ctx.getTitleCaseTranslation('core.domain.trailerType'),
                  class: "form-col",
                  onOnSelect: _ctx.selectTrailerType
                }, null, 8, ["loading", "model-value", "data", "empty-text", "label", "onOnSelect"]),
                _createVNode(_component_text_input, {
                  modelValue: _ctx.state.shipment.trailer,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.shipment.trailer) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.domain.trailerNumber'),
                  cols: "3",
                  class: "form-col"
                }, null, 8, ["modelValue", "label"])
              ]),
              _: 1
            }),
            _createVNode(_component_b_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  modelValue: _ctx.state.shipment.licensePlateNumber,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.shipment.licensePlateNumber) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.domain.licensePlateNumber'),
                  cols: "3",
                  class: "form-col"
                }, null, 8, ["modelValue", "label"]),
                _createVNode(_component_text_input, {
                  modelValue: _ctx.state.shipment.driver,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.state.shipment.driver) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.domain.driver'),
                  cols: "3",
                  class: "form-col"
                }, null, 8, ["modelValue", "label"]),
                _createVNode(_component_text_input, {
                  modelValue: _ctx.state.shipment.seal,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.state.shipment.seal) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.domain.sealNumberShort'),
                  cols: "3",
                  class: "form-col"
                }, null, 8, ["modelValue", "label"])
              ]),
              _: 1
            }),
            _createVNode(_component_b_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_form_textarea, {
                  id: "input-notes",
                  modelValue: _ctx.state.shipment.deliveryNotes,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.state.shipment.deliveryNotes) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.domain.notes'),
                  rows: "4",
                  "max-length": 255
                }, null, 8, ["modelValue", "label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_b_table, {
            items: _ctx.tableData,
            fields: _ctx.fields,
            "row-height": "64px",
            loading: _ctx.state.loading
          }, {
            "cell(imageUrlThumb)": _withCtx((row) => [
              _createVNode(_component_thumbnail, {
                "image-url-thumb": row.item.imageUrlThumb,
                "image-url-full": row.item.imageUrlFull
              }, null, 8, ["image-url-thumb", "image-url-full"])
            ]),
            "cell(estimatedQuantity)": _withCtx((row) => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_quantity_picker, {
                    "model-value": row.item.estimatedQuantity,
                    onChange: (quantity) => _ctx.changeLineQuantity(row.item.item, quantity)
                  }, null, 8, ["model-value", "onChange"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  (row.item.tags.length > 0)
                    ? (_openBlock(), _createBlock(_component_TrackedItemTagModal, {
                        key: 0,
                        tags: row.item.tags,
                        title: row.item.name,
                        onRemoveTag: _ctx.removeTag
                      }, null, 8, ["tags", "title", "onRemoveTag"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            empty: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getTranslation('core.common.noItemsAssociatedWithThisShipment')), 1)
            ]),
            _: 1
          }, 8, ["items", "fields", "loading"]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_b_button, {
              disabled: _ctx.state.loading,
              class: "btn-add-item",
              onClick: _ctx.openItemSearch
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.add')), 1),
                _createVNode(_component_faicon, { icon: "box-open" })
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showSearch,
      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.state.showSearch) = $event)),
      centered: "",
      "disable-escape-key": "",
      "hide-footer": "",
      "hide-header-close": "",
      title: _ctx.getTitleCaseTranslation('core.common.existing'),
      onEnter: _ctx.searchExisting
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "close-search-modal",
          onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.escape && _ctx.escape(...args)))
        }, [
          _createVNode(_component_faicon, { icon: "times" })
        ]),
        _createVNode(_component_b_form, { onSubmit: _ctx.searchExisting }, {
          default: _withCtx(() => [
            _createVNode(_component_b_form_input, {
              modelValue: _ctx.state.search,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.state.search) = $event)),
              type: "number",
              placeholder: _ctx.getTitleCaseTranslation('core.common.scanOrLookupTransactionNumber'),
              autofocus: ""
            }, null, 8, ["modelValue", "placeholder"]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_b_button, {
                type: "submit",
                disabled: _ctx.state.searching
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.search')) + " ", 1),
                  (_ctx.state.searching)
                    ? (_openBlock(), _createBlock(_component_b_spinner, {
                        key: 0,
                        small: ""
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["disabled"])
            ])
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onEnter"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showPrintLabelsModal,
      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.state.showPrintLabelsModal) = $event)),
      centered: "",
      title: _ctx.getTitleCaseTranslation('core.common.numberOfLabelsToPrint'),
      onEnter: _ctx.printLabels
    }, {
      "btn-ok": _withCtx(() => [
        _createVNode(_component_b_button, {
          variant: "primary",
          disabled: _ctx.state.printingLabels,
          onClick: _ctx.printLabels
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.print')) + " ", 1),
            (_ctx.state.printingLabels)
              ? (_openBlock(), _createBlock(_component_b_spinner, {
                  key: 0,
                  small: ""
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_form_input, {
          modelValue: _ctx.state.labelQuantity,
          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.state.labelQuantity) = $event)),
          placeholder: _ctx.getTitleCaseTranslation('core.common.numberOfLabelsToPrint'),
          autofocus: "",
          type: "number"
        }, null, 8, ["modelValue", "placeholder"])
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onEnter"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showItemSearch,
      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.state.showItemSearch) = $event)),
      centered: "",
      "hide-footer": "",
      title: _ctx.getTitleCaseTranslation('core.button.addItem')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_all_or_on_route_item_selector, {
          "allow-quantity-change": "",
          "ignore-combined-containers": "",
          shipping: { fromLocationId: _ctx.state.shipment.fromLocation.id, toLocationId: _ctx.state.shipment.transactions[0].toLocation?.id },
          onOnSelect: _ctx.addItem
        }, null, 8, ["shipping", "onOnSelect"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_smart_trak_footer, { "content-direction": "flex-end" }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_8, [
          _createVNode(_component_b_button, {
            disabled: !_ctx.state.unloadComplete || _ctx.state.shipment.transactions[0].isEstimateFinalized,
            onClick: _ctx.finalizeEstimate
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.finalizeEstimate')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        _createElementVNode("span", _hoisted_9, [
          _createVNode(_component_b_button, {
            disabled: _ctx.state.unloadComplete,
            onClick: _ctx.showPostUnloadSafetyInspection
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.unloadComplete')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        _createElementVNode("span", _hoisted_10, [
          _createVNode(_component_b_dropdown, {
            title: _ctx.getTitleCaseTranslation('core.common.print'),
            "close-on-click": "",
            "drop-up": "",
            "no-caret": "",
            "open-on-hover": "",
            "is-button": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_dropdown_item, {
                disabled: !_ctx.canSubmit,
                onClick: _ctx.printInboundReceipt
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.inboundReceipt')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]),
              _createVNode(_component_b_dropdown_item, {
                disabled: !_ctx.canSubmit,
                onClick: _ctx.openPrintLabelsModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.labels')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]),
            _: 1
          }, 8, ["title"])
        ]),
        _createVNode(_component_b_dropdown, {
          title: _ctx.getTitleCaseTranslation('core.common.receivingActions'),
          "close-on-click": "",
          "drop-up": "",
          "no-caret": "",
          "open-on-hover": "",
          "is-button": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_dropdown_item, {
              disabled: !_ctx.canSubmit,
              onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.saveShipment(_ctx.getTitleCaseTranslation('core.button.saveAndExit'), _ctx.NextView.EXIT)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.saveAndExit')), 1)
              ]),
              _: 1
            }, 8, ["disabled"]),
            _createVNode(_component_b_dropdown_item, {
              disabled: !_ctx.state.shipment.transactions[0].isEstimateFinalized,
              onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.saveShipment(_ctx.getTitleCaseTranslation('core.button.saveAndSort'), _ctx.NextView.SORT)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.saveAndSort')), 1)
              ]),
              _: 1
            }, 8, ["disabled"]),
            _createVNode(_component_b_dropdown_item, {
              disabled: !_ctx.state.unloadComplete,
              onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.saveShipment(_ctx.getTitleCaseTranslation('core.button.saveAndPutAway'), _ctx.NextView.PUT_AWAY)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.saveAndPutAway')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _: 1
    })
  ], 64))
}